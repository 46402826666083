export enum LocationType {
  Hand = 1,
  Flag,
  Zeppelins,
  PrestigiousGuests,
  Column,
  CrewDeck,
  Discard,
  Treasure
}
