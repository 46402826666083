export enum RuleId {
  PlayerTurn = 1,
  Bombing,
  Boarding,
  Fishing,
  Strengthen,
  Secure,
  CrackTheTreasureChest,
  Manoeuvre,
  Recall,
  Observe,
  Loot,
  EndOfCardResolution,
  Scoring
}
